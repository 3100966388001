import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const ProductSection = () => {
  const data = useStaticQuery(graphql`
    query {
      bjorkLogo: file(relativePath: { eq: "Bjork-symbol.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ghdLogo: file(relativePath: { eq: "ghd-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      olaplexLogo: file(relativePath: { eq: "Olaplex_Logo_4877x788.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oAndMLogo: file(relativePath: { eq: "O&M-Logotyp.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sunlightLogo: file(relativePath: { eq: "Sunlights-Logotype.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      evoLogo: file(relativePath: { eq: "EVO-logotyp.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      malibuLogo: file(relativePath: { eq: "malibu_c_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omniblondeLogo: file(relativePath: { eq: "omniblone_logotype.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      k18logo: file(relativePath: { eq: "k18_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      milkshakeLogo: file(relativePath: { eq: "milk_shake.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      beautyWorksLogo: file(relativePath: { eq: "beauty-works-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hhSimonLogo: file(relativePath: { eq: "hhsimonsen_black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      toppikLogo: file(relativePath: { eq: "toppik.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="flex flex-col items-center justify-between py-8 md:py-16 bg-bjerge">
      <div className="flex flex-row items-center justify-around w-full p-4 bg-bjerge">
        <Img
          className="w-24 mx-4 md:mx-0 md:m-0"
          fluid={data.bjorkLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0 "
          fluid={data.ghdLogo.childImageSharp.fluid}
        />
        <Img
          className="w-64 mx-4 md:pl-2 md:mx-0 md:m-0"
          fluid={data.olaplexLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.omniblondeLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.k18logo.childImageSharp.fluid}
        />
      </div>
      <div className="flex flex-row items-center justify-around w-full p-4 bg-bjerge">
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.beautyWorksLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.oAndMLogo.childImageSharp.fluid}
        />
        <Img
          className="w-64 mx-4 md:mx-0 md:m-0"
          fluid={data.sunlightLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.evoLogo.childImageSharp.fluid}
        />
      </div>
      <div className="flex flex-row items-center justify-around w-full p-4 bg-bjerge">
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.malibuLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.milkshakeLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.hhSimonLogo.childImageSharp.fluid}
        />
        <Img
          className="w-48 mx-4 md:mx-0 md:m-0"
          fluid={data.toppikLogo.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}

export default ProductSection
