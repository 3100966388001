import React from "react"
import Anime from "react-anime"

const WelcomeText = () => {
  return (
    <Anime easing="easeInOutSine" duration={800} scale={[0.5, 1.0]}>
      <div className="max-w-4xl flex flex-col">
        <h1 className="inline text-3xl leading-7 text-white font-extrabold">
          Varmt välkomna till oss på Studio Ess
        </h1>
        <p className="inline text-base leading-6 text-white font-light mt-1 lg:leading-7">
          En salong i centrala Ljusdal där Du ska känna dig som hemma. Kom bort
          från den stressiga vardagen, koppla av med en kopp kaffe och bli
          ompysslad av utbildade frisörer. Du får råd och tips om vad just Du
          passar i och vad som fungerar för dig och ditt hår i din vardag. Det
          är Dig vi har i fokus och vi vill ge dig det bästa bemötandet och den
          bästa servicen.
        </p>
      </div>
    </Anime>
  )
}

export default WelcomeText
