import React, { useState } from "react"

const Modal = () => {
  const [showModal, setShowModal] = useState(true)
  return (
    showModal && (
      <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
        <div className="max-w-screen-xl px-2 mx-auto sm:px-6 lg:px-8">
          <div className="p-2 bg-white rounded-lg shadow-lg sm:p-3">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex items-center flex-1 w-0">
                <span className="flex p-2 bg-gray-700 rounded-lg">
                  <svg
                    className="w-6 h-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    />
                  </svg>
                </span>
                <div className="flex flex-col items-start ml-3">
                  <p className="font-medium text-gray-900 truncate">
                    <span className="leading-8 md:hidden">
                      Boka din tid här!
                    </span>
                    <span className="hidden md:leading-8 md:inline">
                      Boka din tid hos oss!
                    </span>
                  </p>
                  <span className="mt-0 italic md:-mt-2 md:leading-8 md:inline">
                    OBS! Sara och Sofies eftermiddagstider är begränsade via
                    onlinebokningen. Hittar du ingen tid? Ring oss istället
                  </span>
                </div>
              </div>
              <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                <div className="border border-indigo-600 border-solid rounded-md shadow-xl">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://bokning.voady.se/studioess"
                  >
                    <span className="flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-indigo-600 transition duration-150 ease-in-out border border-transparent rounded-md hover:text-indigo-500 focus:outline-none focus:shadow-outline">
                      Boka tid
                    </span>
                  </a>
                </div>
              </div>
              <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="flex p-2 -mr-1 transition duration-150 ease-in-out rounded-md hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
                  aria-label="Dismiss"
                >
                  <svg
                    className="w-6 h-6 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Modal
