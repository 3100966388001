import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "alla_tre.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "stolar.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div style={{ maxHeight: "100%", height: "400" }}>
      <Img
        className="relative"
        style={{ maxHeight: "50%" }}
        fluid={data.backgroundImage.childImageSharp.fluid}
      />
    </div>
  )
}

export default Image
