import React from "react"
import Image from "../components/image"
import SEO from "../components/seo"
import WelcomeText from "../components/welcomeText"
import Cta from "../components/cta"
import Header from "../components/header"
import Footer from "../components/footer"
import ProductSection from "../components/productsection"

function IndexPage() {
  return (
    <>
      <SEO title="Studio Ess" />
      <div className="bg-ess-bjerge">
        <div style={{ filter: `grayscale(100%)`, maxHeight: `90vh` }}>
          <Image />
          <div className="px-4 md:px-0 lg:fixed right-0 md:mr-16 z-20 mt-4 md:bottom-0 mb-0 md:mb-12 ">
            <WelcomeText />
          </div>
        </div>
        <ProductSection />
        <div className="w-full absolute top-0">
          <Header />
        </div>
        <Cta />
      </div>
      <div className="w-full bg-gray-900">
        <Footer />
      </div>
    </>
  )
}

export default IndexPage
